<template>
    <div class="text-center position-relative style-component">
        <b-button-group>
            <b-button @click.prevent="goView" class="style-btn mr-2" :class="{ 'style-color': check == 'statistic' }"
                variant="info">Tổng quan</b-button>
            <b-button @click.prevent="goDetail" class="style-btn" :class="{ 'style-color-success': check == 'detail' }"
                variant="info">Danh sách chi tiết</b-button>
        </b-button-group>
        <b-link class="style-link position-absolute style-right" @click.prevent="saveToFile()"><i
                class="bx bx-download mr-2"></i>
            Tải xuống tệp excel
        </b-link>
    </div>
</template>

<script>
import { httpClient } from '../../../../_utils/httpClient'
import { httpPreview } from '../../../../_utils/httpPreview'
export default {
    data() {
        return {
            docId: '',
            check: 'view',
        }
    },
    methods: {
        goDetail() {
            if (this.$route.name == 'admin.surveys.detail') return;
            this.$router.push({ name: 'admin.surveys.detail', params: { id: this.docId } })
        },
        goView() {
            if (this.$route.name == 'admin.surveys.statistic') return;
            this.$router.push({ name: 'admin.surveys.statistic', params: { id: this.docId } })
        },
        downloadFile(media) {
            if (media && media.startsWith(`http`)) return media
            return httpPreview + media
        },
        async saveToFile() {
            let docId = this.$route.params.id
            let response = await httpClient.get(`rate/export-excel-rate`, {
                responseType: 'blob',
                params: {
                    docId
                }
            })
            var blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });
            console.log();
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = response.headers['content-disposition'].replace("attachment; filename=", "");
            link.click();
        },
    },
    async created() {
        this.docId = this.$route.params.id
        if (this.$route.name == 'admin.surveys.statistic') this.check = 'statistic'
        else this.check = 'detail'
    }
}
</script>

<style scoped>
.style-btn {
    border-radius: 6px !important;
    min-width: 152px;
    padding: 10px 0;
}

.style-color {
    background-color: #3d80bc;
}

.style-color-success {
    background-color: #3d80bc;
}

.style-link {
    color: #50a5f1;
    border: 1px solid #50a5f1;
    padding: 10px;
    border-radius: 4px;
    min-width: 143px !important;
}

.style-link:hover {
    background-color: #50a5f1;
    color: #fff;
}

.style-right {
    right: 0;
    top: -1px;
}

@media (max-width: 757px) {
    .style-component {
        height: 100px;
    }

    .style-btn {
        transform: translateY(120%);
    }
}
</style>