import { render, staticRenderFns } from "./btn-change-page.vue?vue&type=template&id=50bc858f&scoped=true&"
import script from "./btn-change-page.vue?vue&type=script&lang=js&"
export * from "./btn-change-page.vue?vue&type=script&lang=js&"
import style0 from "./btn-change-page.vue?vue&type=style&index=0&id=50bc858f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50bc858f",
  null
  
)

export default component.exports